<template>
  <div class="service">
    <div class="title">
      <span>系统设置 / </span> <span class="black">系统用户</span>
    </div>
    <el-card class="condition_style2">
      <el-form :inline="true" class="flex" :model="searchForm">
        <el-form-item>
          <el-button type="primary" size="small" @click="add()">新增</el-button>
        </el-form-item>
        <div>
          <el-form-item>
            <el-input v-model="searchForm.userName" placeholder="请输入登录账号" suffix-icon="el-icon-search" clearable>
            </el-input>
          </el-form-item>
          <!-- <el-form-item>
              <el-select
                v-model="searchForm.roleId"
                @focus="getRoleList"
                placeholder="请选择角色"
              >
                <el-option
                  v-for="(item, index) in roleList"
                  :key="index"
                  :label="item.roleName"
                  :value="item.roleId"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item>
            <el-select v-model="searchForm.flag" placeholder="请选择状态" clearable>
              <el-option v-for="(item, index) in list" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.name" placeholder="请输入用户名" suffix-icon="el-icon-search" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(searchForm.page-1) * searchForm.limit + 1}}</template>
        </el-table-column>
        <el-table-column prop="phone" label="登录账户" min-width="200" align="center"></el-table-column>
        <el-table-column prop="name" label="用户名" min-width="120" align="center"></el-table-column>
        <el-table-column prop="roleEntities" label="角色" min-width="120" align="center">
          <template slot-scope="scope" v-if="scope.row.roleEntities.length > 0">
            <div v-for="(item, index) in scope.row.roleEntities" :key="index">{{ item.roleName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="flag" label="状态" min-width="100" align="center">
          <template slot-scope="scope">
            <span class="dot" :class="stateClass(scope.row.flag)"></span>
            {{ showState(scope.row.flag) }}
          </template>
        </el-table-column>
        <el-table-column prop="updatetime" label="最后操作时间" min-width="180" align="center"></el-table-column>
        <el-table-column prop="updateName" label="操作人" min-width="120" align="center"></el-table-column>
        <el-table-column prop="memo" label="备注" min-width="120" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.Entities!=false">
              <el-button type="text" size="small" class="ge" @click="changeState(scope.row)">
                {{ scope.row.flag == 1 ? "禁用" : scope.row.flag == 0 ? "启用" : "" }}</el-button>
              <span class="ge">|</span>
              <el-button type="text" size="small" class="ge" @click="add(scope.row.id)"> 编辑</el-button>
              <span class="ge">|</span>
              <el-button type="text" size="small" @click="reset(scope.row.id)"> 重置密码</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>

    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="登录账户：" prop="phone" v-if="name">
          <el-input v-model="addForm.phone" placeholder="请输入登录账户" disabled @blur="getFindByName" maxlength="18">
          </el-input>
        </el-form-item>
        <el-form-item label="登录账户：" prop="phone" v-else>
          <el-input v-model="addForm.phone" placeholder="请输入登录账户" @blur="getFindByName" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="name">
          <el-input v-model="addForm.name" placeholder="请输入用户名" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" :disabled="passwordRule?true:false" type="password" maxlength="12"
            placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password1">
          <el-input v-model="addForm.password1" type="password" :disabled="passwordRule?true:false" maxlength="12"
            placeholder="请输入密码" @input="$forceUpdate()">
          </el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="roleList">
          <el-select v-model="addForm.roleList" placeholder="请选择角色" class="w_100" multiple @change="changeRole"
            @remove-tag="remove">
            <el-option v-for="(item, index) in roleList" :key="index" :label="item.roleName" :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="flag">
          <el-select v-model="addForm.flag" placeholder="请选择状态" class="w_100">
            <el-option v-for="(item, index) in addList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="memo">
          <el-input type="textarea" v-model="addForm.memo" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </modal>
    <!-- 重置密码 -->
    <modal :obj="{
        title: '重置密码',
        dialog: resetDialog,
        dialogText: 'resetDialog',
        form: resetForm,
        ref: 'resetForm',
      }" @submit="resetSubmit">
      <el-form :model="resetForm" label-width="130px" ref="resetForm">
        <el-form-item label="新密码：" prop="password"
          :rules="[{ required: true, message: '请输入新密码' }, {min: 6,max: 12,message: '长度在 6 到 12 个字符'}]">
          <el-input v-model="resetForm.password" placeholder="请输入新密码" type="password" maxlength="12" show-password>
          </el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    getOperationList,
    operationSave,
    operationUpdate,
    operationUpdown,
    clearPWD,
    findAllOneList,
    findByName,
    operationInfo,
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      const checkRole = (rule, value, callback) => {
        if (!this.addForm.roleList.length) {
          return callback(new Error('请选择角色'))
        }
        return callback()
      }
      return {
        passwordRule: '',
        page: 1,
        limit: 10,
        total: 0,
        tableHeight: this.$util.getHeight(330),
        searchForm: {
          flag: "",
          limit: 10,
          name: "",
          page: 1,
          userName: "",
        },
        tableData: [],
        roleList: [],
        list: [{
            value: null,
            label: "全部",
          }, {
            value: "0",
            label: "禁用",
          },
          {
            value: "1",
            label: "启用",
          },
        ],
        addList: [{
            value: "0",
            label: "禁用",
          },
          {
            value: "1",
            label: "启用",
          },
        ],
        showBtn: false,
        addDialog: false,
        resetDialog: false,
        edit: "",
        roleId: "",
        addForm: {
          phone: "",
          name: "",
          flag: "",
          memo: "",
          id: "",
          password: '',
          password1: '',
          roleList: [],
          roleEntities: [],
        },
        resetForm: {
          password: "",
          id: "",
        },
        rules: {
          phone: [{
            required: true,
            message: "请输入登录账户"
          }],
          name: [{
            required: true,
            message: "请输入用户名"
          }],
          roleList: [{
            required: true,
            validator: checkRole,
            trigger: 'change',
            message: "请选择角色"
          }],
          flag: [{
            required: true,
            message: "请选择状态"
          }],
          password: [{
            required: true,
            message: "请输入密码"
          }, {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
          }],
          password1: [{
            required: true,
            message: "请输入密码"
          }, {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
          }],
        },
        name: "", //编辑时获取到的登录账户，用来标志编辑
        role: [], //用来比较角色是否被修改
      };
    },
    computed: {
      userInof() {
        return this.$store.state.userInof;
      },
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },

    },
    watch: {
      "addForm.phone"(val, oldVal) {
        this.addForm.phone = this.$util.isEnNum(val);
      },
      "searchForm.userName"(val, oldVal) {
        this.searchForm.userName = this.$util.isNumber0(val);
      },
    },
    created() {
      this.getList();
      this.getRoleList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(330)
      })
    },
    methods: {
      getList(val) {
        if (val) {
          this.searchForm.page = 1;
        }
        this.$request(getOperationList, this.searchForm).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            data.list.map((item) => {
              if (item.roleEntities || item.roleEntities.length > 0) {
                item.roleEntities.find((val) => {
                  if (val.roleId == "1") {
                    item.Entities = false
                  } else {
                    item.Entities = true
                  }

                })
              }

            })
            this.tableData = data.list
            this.total = Number(data.totalCount);
          }
        });
      },
      remove() {
        this.$forceUpdate();

      },
      //   新增\编辑-弹框
      add(val) {
        this.passwordRule = ""
        this.name = ""
        this.addForm = {
          phone: "",
          name: "",
          flag: "",
          memo: "",
          id: "",
          roleList: [],
          roleEntities: [],
        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        this.edit = val;
        this.addDialog = true;
        if (val) {
          this.getInfo(val);
        }
      },
      changeRole() {
        this.$forceUpdate();
        this.$refs.addForm.validate((result) => {
          return true
        })
      },
      // 查询单个信息
      getInfo(val) {
        this.$request(operationInfo(val)).then((res) => {
          if (res.code === "200") {
            this.addForm = res.data;
            this.name = this.addForm.phone;
            this.role = res.data.roleEntities
            this.addForm.roleList = [];
            if (this.addForm.roleEntities && this.addForm.roleEntities.length) {
              this.addForm.roleEntities.find((item) => {
                this.addForm.roleList.push(item.roleId);
              });
            } else {
              this.addForm.roleEntities = [];
            }
            this.addForm.password1 = this.addForm.password;
            this.passwordRule = this.addForm.password
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.addForm.roleEntities = [];
            if (this.addForm.password != this.addForm.password1) {
              this.$util.message("err", "密码和确认密码不一致");
              return
            }
            this.roleList.find((item) => {
              this.addForm.roleList.find((val) => {
                if (item.roleId == val) {
                  this.addForm.roleEntities.push({
                    roleId: val,
                    roleName: item.roleName,
                  });
                }
              });
            });

            this.addForm.password = this.$util.Encrypt(this.addForm.password)
            this.addForm.password1 = this.$util.Encrypt(this.addForm.password1)
            this.$request(
              this.edit ? operationUpdate : operationSave,
              this.addForm
            ).then((res) => {
              if (res.code === "200") {
                if (res.data == "1") {
                  this.$router.push('/login')
                  this.$util.message("err", "用户信息已被修改！");
                  return
                }
                if (res.data == "2") {
                  this.$common.getUserMenuList()

                }

                this.$util.message("suc", "操作成功");
                this.addDialog = false;
                this.getList();
              }
            });
          }
        });
      },
      // 账户校验接口
      getFindByName() {
        if (this.name == this.addForm.phone || !this.addForm.phone) return
        this.$request(findByName(this.addForm.phone)).then((res) => {
          if (res.code === "200") {
            if (res.data == "不可用") {
              this.addForm.phone = this.name || "";
              this.$util.message("err", "登录账户已存在");
            }
          }
        });
      },
      getRoleList() {
        // if (this.roleList.length) return;
        this.$request(findAllOneList).then((res) => {
          if (res.code === "200") {
            this.roleList = res.data;
          }
        });
      },
      // 启用禁用
      changeState(val) {
        this.$util.isSwal(
          val.flag == 1 ? "确定禁用？" : "确定启用？",
          this.changeStateSubmit,
          val
        );
      },
      changeStateSubmit(val) {
        this.$request(operationUpdown(val.id, val.flag == 0 ? "1" : "0")).then(
          (res) => {
            if (res.code === "200") {
              if (res.data == "1") {
                this.$router.push('/login')
                this.$util.message("err", "账户已被禁用 请联系管理员!");
                return
              }
              this.$util.message("suc", "操作成功");
              this.getList();
            }
          }
        );
      },
      // 重置密码-点击
      reset(val) {
        this.resetForm = {
          password: "",
          id: "",
        };
        if (this.$refs.resetForm) {
          this.$refs.resetForm.resetFields();
        }
        this.resetDialog = true;
        this.resetForm.id = val;
      },
      // 重置密码-接口
      resetSubmit() {
        this.$refs.resetForm.validate((result) => {
          if (result) {
            this.$request(clearPWD, {
              password: this.$util.Encrypt(this.resetForm.password),
              id: this.resetForm.id
            }).then((res) => {
              if (res.code === "200") {
                if (res.data == "1") {
                  this.$router.push('/login')
                  this.$util.message("err", "密码已重置  请输入新密码");
                  return
                }
                this.$util.message("suc", "密码已重置");
                this.getList();
                this.resetDialog = false;
              }
            });
          }
        });
      },
      handleSizeChange(val) {
        this.searchForm.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchForm.page = val;
        this.getList();
      },
      showState(val) {
        switch (val) {
          case "0":
            return "禁用";
          case "1":
            return "启用";
        }
      },
      stateClass(val) {
        switch (val) {
          case "0":
            return "red_dot";
          case "1":
            return "green_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .service {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }
  }
</style>